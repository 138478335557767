// store.js
import { reactive, toRef } from "vue";

export const store = reactive({
    hasAdblocker: true,
    commentToReply: null,
    commentToReplyName: null,
    contentSidebar: {
        show: false,
        sidebarId: null,
        data: null,
        postTags: null,
        slug: null
    },
    postId: null,
    isLoading: false,
    isContentDoneLoading: false,
    toggleAdBlocking(value) {
        this.hasAdblocker = value;
    },
    toggleLoading() {
        this.isLoading = !this.isLoading;
    },
    manualToggleLoading(val) {
        this.isLoading = val;
    },
    toggleContentSidebarVisibility(visibility) {
        this.contentSidebar = { ...this.contentSidebar, show: visibility };
    },
    setContentSidebarId(id) {
        this.contentSidebar = { ...this.contentSidebar, sidebarId: id };
    },
    setContentSidebarTitle(slug) {
        this.contentSidebar = { ...this.contentSidebar, slug };
    },
    setPostsTags(tags) {
        this.contentSidebar = { ...this.contentSidebar, postTags: [...tags] };
    },
    setContentSidebarData(payload) {
        this.contentSidebar = { ...this.contentSidebar, data: payload };
    },
    changePostId(payload) {
        this.postId = payload
    },
    contentDoneLoading() {
        this.isContentDoneLoading = false;

        setTimeout(() => {
            this.isContentDoneLoading = true;

        }, 500)
    },
    setCommentIdToReply(payload) {
        this.commentToReply = payload.id
        this.commentToReplyName = payload.name
    },
});

export const commentToReplyNameRef = toRef(store, "commentToReplyName");
