<template>
	<Head>
		<link
			rel="preload"
			as="font"
		/>
		<link
			rel="preconnect"
			href="https://fonts.googleapis.com"
		/>
		<link
			rel="preconnect"
			href="https://fonts.gstatic.com"
			crossorigin
		/>
	</Head>

	<Navigation />

	<Transition>
		<ContentSidebar v-if="store.contentSidebar.show" />
	</Transition>

	<slot />

	<Footer />

	<AdButlerModal
		v-if="showAds && showAdButlerModal"
		:showAdButlerModal="showAdButlerModal"
		@close="hideModal"
	/>
</template>

<script setup>
const { one_signal_key, gtm_id, one_signal_safari_key, env } =
	useRuntimeConfig().public;

const showAds = ref(false);
const $route = useRoute();
const showAdButlerModal = ref(false);

watch(
	() => $route.name,
	(newPath) => {
		if (newPath !== "blog-slug" && newPath !== "widgetpage") {
			store.toggleContentSidebarVisibility(false);
			store.setContentSidebarId(null);
			store.setContentSidebarData(null);
		}
	}
);
useHead(() => {
	const head_obj = {
		bodyAttrs: {
			class: "full-width-content"
		},
		link: [
			{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
			{ rel: "manifest", href: "/site.webmanifest" },
			{
				rel: "icon",
				type: "image/png",
				sizes: "16x16",
				href: "/fav-16x16.png"
			},
			{
				rel: "icon",
				type: "image/png",
				sizes: "32x32",
				href: "/fav-32x32.png"
			},
			{ rel: "mask-icon", color: "#5BBAD5", href: "/safari-pinned-tab.svg" },
			{ rel: "icon", type: "apple-touch-icon", href: "/apple-touch-icon.png" }
		],
		meta: [
			{ name: "msapplication-TileColor", content: "#DA532C" },
			{ name: "theme-color", content: "#FFFFFF" }
		],
		script: [{ src: "/js/prebid.js" }]
	};

	if (env === "production") {
		head_obj.script = [
			{ src: "/js/prebid.js" },
			{
				async: true,
				src: `https://www.googletagmanager.com/gtm.js?id=${gtm_id}`,
				body: true
			},
			{
				async: true,
				src: `https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js`,
				body: true
			}
		];
	}

	return head_obj;
});

const loadAccessibe = () => {
	const s = document.createElement("script");
	const h = document.querySelector("head") || document.body;
	s.src = "https://acsbapp.com/apps/app/dist/js/app.js";
	s.async = true;
	s.onload = function () {
		acsbJS.init({
			statementLink: "",
			footerHtml: "",
			hideMobile: false,
			hideTrigger: false,
			disableBgProcess: false,
			language: "en",
			position: "right",
			leadColor: "#146FF8",
			triggerColor: "#146FF8",
			triggerRadius: "50%",
			triggerPositionX: "right",
			triggerPositionY: "bottom",
			triggerIcon: "people",
			triggerSize: "bottom",
			triggerOffsetX: 20,
			triggerOffsetY: 20,
			mobile: {
				triggerSize: "small",
				triggerPositionX: "right",
				triggerPositionY: "bottom",
				triggerOffsetX: 10,
				triggerOffsetY: 10,
				triggerRadius: "20"
			}
		});
	};
	h.appendChild(s);
};

const hideModal = () => {
	localStorage.setItem("showAdButlerModal", "false");
	showAdButlerModal.value = false;
};

onMounted(async () => {
	if (process.client) {
		if (window.adsAreWithUs) {
			showAds.value = true;

			/*	eslint-disable  prefer-const, no-undef */
			let timer;
			let timerCounter = 0;
			let scrollTimeout;

			function detectScrollStop(callback) {
				clearTimeout(scrollTimeout);
				scrollTimeout = setTimeout(callback, 3000); // 3000 milliseconds = 3 seconds
			}

			timer = await setInterval(() => {
				const shouldShowAdButlerModal = localStorage.getItem("showAdButlerModal");

				console.log(
					"shouldShowAdButlerModal",
					shouldShowAdButlerModal,
					`${timerCounter} seconds`
				);

				function scrollHandler() {
					detectScrollStop(function () {
						const shouldShowAdButlerModal = localStorage.getItem("showAdButlerModal");

						const seconds_to_wait = env === "development" ? 3 : 6;

						if (
							timerCounter >= seconds_to_wait &&
							shouldShowAdButlerModal === "true"
						) {
							localStorage.setItem("showAdButlerModal", "true");
							showAdButlerModal.value = true;

							setTimeout(() => {
								hideModal();
								window.removeEventListener("scroll", scrollHandler);
							}, 20000);

							clearInterval(timer);
						}

						if (shouldShowAdButlerModal === "false" || !shouldShowAdButlerModal) {
							window.removeEventListener("scroll", scrollHandler);
							clearInterval(timer);
						}
					});
				}

				if (
					shouldShowAdButlerModal == null ||
					JSON.parse(shouldShowAdButlerModal) === true
				) {
					localStorage.setItem("showAdButlerModal", "true");
					window.addEventListener("scroll", scrollHandler);
				} else {
					localStorage.setItem("showAdButlerModal", "false");
					clearInterval(timer);
					return;
				}

				timerCounter += 1;
			}, 1000);
		}
		if (env === "production") {
			const script2 = document.createElement("script");

			script2.innerHTML = `
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      OneSignalDeferred.push(function(OneSignal) {
        OneSignal.init({
          appId: "${one_signal_key}",
          safari_web_id: "${one_signal_safari_key}",
          notifyButton: {
            enable: false, // Disable the default bell notification button
          },
          promptOptions: {
            /* Customize the slide-in prompt here */
            slidedown: {
              enabled: true, // Enable the slide-in prompt
              autoPrompt: true, // Show the prompt automatically
              actionMessage: "Subscribe to receive notifications",
              acceptButtonText: "Allow",
              cancelButtonText: "No, thanks",
            },
          },
          allowLocalhostAsSecureOrigin: true,
        });
      });
    `;
			document.body.appendChild(script2);
		}
	}

	loadAccessibe();
});
</script>

<style lang="scss">
@import "../assets/utils.scss";

.swiper-slide p {
	font-size: 15.2px;
}

.swiper-slide-button.prev {
	left: -36px;
	top: -52px;
}

.swiper-slide-button.next {
	right: -36px;
	top: -52px;
}

.testimonial-text .testimonial-author {
	font-size: 20px;
}

.testimonial-text h5 {
	font-size: 10.5px;
}

@media (min-width: 768px) {
	.swiper-slide p {
		font-size: 19px;
	}

	.swiper-slide-button.prev {
		left: 0;
		top: -32px;
	}

	.swiper-slide-button.next {
		right: 0;
		top: -32px;
	}
}

.tablepress {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border: 1px solid $neutral-200;
	margin-bottom: 32px;
	overflow: auto;
	display: block;
	@media (min-width: 768px) {
		display: table;
	}
	tr:nth-child(2n + 1) {
		background-color: #fff;
	}

	th {
		padding-top: 11px;
		padding-bottom: 11px;
		background-color: $primary-500;
		color: white;
	}

	td,
	th {
		text-align: left;
		padding: 12px 8px;
		white-space: nowrap;
	}
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px transparent;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 transparent;
	}
}

.blob.red {
	background: #ff5252;
	box-shadow: 0 0 0 0 #ff5252;
	animation: pulse-red 2s infinite;
}

.blob {
	background: #000;
	border-radius: 50%;
	box-shadow: 0 0 0 0 #000;
	margin: 14px 8px 15px 12px;
	height: 10px;
	width: 10px;
	transform: scale(1);
	animation: pulse-black 2s infinite;
}

.nav-link {
	color: #fff;
	font-family: Fira Sans;
	font-size: 20px;
	font-style: normal;
	height: 38px;
	font-weight: 400;
	line-height: 40px;
	/* 36px */
	letter-spacing: 0.44px;
	position: relative;
	padding: 0 16px;
	visibility: visible;
	display: block;
	&.live {
		border-radius: 8px;
		background: $error-700;
		display: flex;
		padding: 6px 16px;
		align-items: center;
		font-weight: 600;
		gap: 8px;
		line-height: 1;
		font-size: 16px;
		color: white;

		&:hover {
			background: $error-500;
		}

		@include breakpoint(lg) {
			margin-left: 16px;
		}
	}
}

.ad-buttler-ribbon-outer-wrapper {
	background-color: #ffeb3b;
}

.ad-buttler-ribbon-outer-wrapper p {
	color: #222;
}

.plc719265 {
	display: none;
}

.author-body {
	a:not(.author-publications) {
		font-size: 18px;
		display: revert-layer;
		align-items: center;
		position: relative;
		color: $primary-500;
		line-height: normal;
		&::after {
			content: "";
			background-image: url("/images/icons/external-link.svg");
			width: 14px;
			height: 14px;
			display: inline-block;
			background-size: contain;
			margin-left: 2px;
		}

		&:hover {
			background-color: #eab308;
			color: #1e3a8a;
			text-decoration: underline;
			&::after {
				background-image: url("/images/icons/external-link-hover.svg");
			}
		}
	}
}

.author-title a {
	color: #93c5fd;
}
</style>
