<template>
	<NuxtLink
		:to="`/blog/${slug}/`"
		class="post-tile"
	>
		<div
			class="post-image"
			:style="`background-image:url(${parsedImage})`"
		/>
		<div class="post-content">
			<div class="post-metadata mb-[8px]">
				<span>{{ truncateText(categoryParsed, 15) }}</span>
				<span>{{ calculateReadingTime(content) }} min read</span>
			</div>
			<h3 v-html="reponsiveTruncateText(title)" />
		</div>
	</NuxtLink>
</template>

<script setup lang="ts">
import categories from "~/utils/categories-data.json";

type Post = {
	sourceUrl: string;
	slug: string;
	title: string;
	category: number;
	content: string;
};

const { category, sourceUrl } = defineProps<Post>();

const categoryParsed = computed(() => {
	const el = categories.find((item) => item.id === category);
	if (el) {
		return el.name;
	}

	return null;
});

const parsedImage = computed(() => {
	return `https://content.timothysykes.com/cdn-cgi/image/quality=90,format=webp,width=440/${sourceUrl}`;
});
const reponsiveTruncateText = (text: string) => {
	if (process.client) {
		if (window.innerWidth > 1280) {
			return truncateText(text, 49);
		}

		return truncateText(text, 62);
	} else {
		return truncateText(text, 49);
	}
};
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.post-tile {
	border-radius: 8px;
	overflow: hidden;
	filter: drop-shadow(0px 7px 20px rgba(0, 0, 0, 0.1));
}

.post-image {
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background-color: $primary-300;
	overflow: hidden;
	background-size: cover;
	background-position: center;
}

.post-content {
	padding: 8px 16px 16px;
	background-color: white;

	@include breakpoint(md) {
		min-height: 92px;
		height: 100%;
	}
}

.post-content h3 {
	color: $neutral-800;
	/* Heading/H6 */
	font-family: Fira Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
	/* 21.6px */
}
</style>
