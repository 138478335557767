<template>
	<div :class="'slide-message' + (isActive ? ' active' : '')">
		<button
			class="slide-dismiss-button"
			@click="onClose"
		>
			<img
				src="/images/close_gray.svg"
				alt="close btn for slide-up"
				width="20"
				height="20"
				class="slide-up-close"
			/>
			<!-- <span class="material-icons">close</span> -->
		</button>
		<span
			class="swipe-up-button-invisible"
			@touchmove.passive="swipeUpHandler"
		/>
		<button class="swipe-up-button">
			<img
				src="/images/expand_less.svg"
				alt="close btn for slide-up"
				width="20"
				height="20"
				class="slide-up-close"
			/>

			<div class="swipe-up-title-block">
				Swipe Up
				<span
					style="margin-left: 8px"
					class="black"
				>
					{{ swipeTitle }}
				</span>
			</div>
		</button>
		<template v-if="!step_forward">
			<h3
				class="swipe-title black"
				style="padding-bottom: 0"
			>
				Ready to reclaim your life through trading profits
			</h3>

			<img
				src="/images/sykes-profile-sm.webp"
				alt="Tim Sykes"
				class="swipe-image"
				width="160"
				height="160"
				quality="55"
				fit="inside"
				style="width: 160px; height: 160px"
				loading="lazy"
			/>
			<div class="swipe-up-content">
				<p class="black text-left">
					<b>Buy back your time!</b>
					- no one should dread going to work every day.
				</p>
				<p class="black text-left">
					<b>Take control of your future!</b>
					- Create the life you want, or someone will create one for you.
				</p>
				<p class="black text-left">
					<b>You deserve FREEDOM!</b>
					I can teach you how to create it.
				</p>
			</div>
			<button
				class="swipe-cta"
				@click="onForward"
			>
				BOOK A CALL
			</button>
		</template>
		<div
			ref="infoForm"
			style="display: none"
		>
			<h3
				class="swipe-title"
				style="padding-bottom: 0"
			>
				Book a Call with My Team
			</h3>
			<div action="step-2">
				<input
					ref="emailField"
					:class="`swipe-up-input ${isValidEmail ? '' : 'invalid-field'}`"
					type="text"
					name="Email"
					placeholder="Email"
					@blur="validateEmail"
				/>

				<label v-if="!isValidEmail">Invalid Email</label>

				<div class="swipe-up-input-container">
					<select
						ref="country_code"
						class="swipe-up-input swipe-up-select"
						name="country_code"
					>
						<option
							v-if="countryOptions.length === 0"
							value=""
							selected=""
							disabled=""
							data-icon="gd-ico-global"
						>
							Select country...
						</option>
						<option
							v-for="(option, i) in countryOptions"
							v-else
							:key="i"
							:value="option.value"
							:data-country="option['data-country']"
							data-icon="gd-ico-global"
						>
							{{ option.innerText }}
						</option>
					</select>
					<input
						id=""
						ref="phoneRef"
						type="text"
						placeholder="Enter your phone (Optional)"
						name="phone"
						class="swipe-up-input"
					/>
				</div>
				<button
					:disabled="isValidEmail ? false : true"
					class="swipe-cta"
					@click="submitData"
				>
					SUBMIT
				</button>
			</div>
		</div>
		<button
			class="close-button"
			@click="closeModal"
		>
			<img
				src="/images/expand_more.svg"
				alt="close btn for slide-up"
				width="20"
				height="20"
				class="slide-up-close"
			/>

			Close
		</button>
	</div>
</template>

<script setup>
import axios from "axios";

const $config = useRuntimeConfig();
// eslint-disable-next-line
const { onSwipeClose } = defineProps(["swipeTitle", "onSwipeClose"]);

const infoForm = ref();
const emailField = ref();
const phoneRef = ref();
const country_code = ref();
const isActive = ref(false);
const step_forward = ref(false);
const isValidEmail = ref(true);
const countryOptions = ref([]);

const swipeUpHandler = () => {
	isActive.value = true;
};

const validateEmail = () => {
	(async () => {
		const email = emailField.value;

		const { data } = await axios.get(
			`https://timsykes-supernova.com/iw/iwverify.php?EMAIL=${email.value}`
		);

		if (data === "ACCEPT") {
			isValidEmail.value = true;
		} else {
			isValidEmail.value = false;
		}
	})();
};

const setCookie = (cname, cvalue, duration, duration_type = "D") => {
	const d = new Date();

	let mul = 24 * 60 * 60; // Days
	if (duration_type === "H") {
		// Hours
		mul = 60 * 60;
	} else if (duration_type === "M") {
		// Minutes
		mul = 60;
	}

	d.setTime(d.getTime() + duration * mul * 1000);
	const expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const hideModal = () => {
	document.querySelector(".slide-message").style.display = "none";
};

const closeModal = () => {
	isActive.value = false;
	step_forward.value = false;
	infoForm.value.style.display = "none";
};

const onChangeCountry = async () => {
	const { data } = await axios.get(
		`${$config.public.hostname}wp-json/gridsome/v1/phone-codes`
	);

	countryOptions.value = [...data];
};

const submitData = () => {
	const phone = `${country_code.value.value} ${phoneRef.value.value}`;
	const email = emailField.value.value;
	const funnel = "ts_challenge";
	const action = "opt_in";
	const page_id = "ts_blog";
	const utm_source = "";
	const utm_medium = "";
	const utm_campaign = "";
	const utm_content = "";
	const utm_term = "";

	(async () => {
		const { data, status } = await axios.get(
			`https://timsykes-supernova.com/iw/iwverify.php?EMAIL=${email}`
		);

		if (data === "ACCEPT") {
			const params = `email=${email}&funnel=${funnel}&action=${action}&phone=${phone}&page_id=${page_id}
                        &utm_source=${utm_source}
                        &utm_medium=${utm_medium}
                        &utm_campaign=${utm_campaign}
                        &utm_content=${utm_content}
                        &utm_term=${utm_term}`;

			const response = await axios.get(
				`https://timsykes-supernova.com/ctdb/track.php?${params}`
			);

			// Simulate an HTTP redirect:
			window.location.replace(
				`https://lp.timothysykes.com/millionaire-challenge/step2.php?email=${email}&phone=${phone}`
			);

			return response;
		}

		if (status === 200) {
			closeModal();
		}
	})();
};

const onForward = () => {
	step_forward.value = true;

	if (countryOptions.value.length === 0) {
		onChangeCountry();
	}

	const container = infoForm.value;

	container.style.display = "block";
	window.dispatchEvent(new Event("resize"));

	const disclaimer = document.querySelector(".se_step_2 div ~ small");

	disclaimer.style.marginTop = "20px";
	disclaimer.style.display = "block";

	const step_container = document.querySelector(".se_step_2");

	step_container.appendChild(disclaimer);
};

const onClose = () => {
	hideModal();
	setCookie("mdsphd", "true", 7);
	const button = document.querySelector(".left-fab");
	if (button) {
		button.classList.remove("site-has-message");
	}

	onSwipeClose();
};
</script>

<style lang="scss">
#info-form {
	display: none;
	pointer-events: none;
}

.slide-message.active #info-form {
	display: block;
	pointer-events: initial;
}

.close-button {
	border: unset;
	background-color: transparent;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	bottom: 0;
	position: fixed;
	width: calc(100% - 40px);
	text-align: center;
	justify-content: center;
	color: #888;
	font-size: 16px;
	background-color: white;

	.material-icons {
		top: 0;
	}
}

.slide-message {
	background-color: white;
	border-top-left-radius: 32px;
	border-top-right-radius: 32px;
	padding: 12px 20px;
	width: 100vw;
	position: fixed;
	bottom: 0;
	z-index: 998;
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
	height: 70px;

	.slide-dismiss-button {
		display: flex;
		z-index: 10000;
		position: absolute;
		justify-content: center;
		align-items: center;
		margin: 0px -14px 0px;
		height: 50px;
		background: none;
		border: none;

		.material-icons {
			color: #c4c4c4;
			font-size: 20px;
		}
	}

	&.active {
		.slide-dismiss-button {
			display: none;
		}
	}
}

.slide-message:not(.active) .close-button {
	bottom: -60px;
}

.swipe-up-button {
	position: relative;
}

.swipe-up-button-invisible {
	position: absolute;
	height: 84px;
	width: 100%;
	content: "";
	display: block;
	top: -36px;
	left: 0;
	margin: auto;
	z-index: 1;
}

@keyframes slideDown {
	100% {
		height: 100%;
		max-height: 620px;
	}

	0% {
		height: 76px;
	}
}

@keyframes slideUp {
	0% {
		height: 76px;
	}

	100% {
		height: 100%;
		max-height: 620px;
	}
}

.slide-message.active .swipe-up-button {
	top: -60px;
	display: none;
}

.slide-message.active {
	height: auto;
	max-height: 620px;
	overflow-y: auto;
	transition: all 400ms ease;
	animation-name: slideUp;
	animation-duration: 0.25s;
	animation-timing-function: ease-in-out;
	/* min-height: 400px; */
	/* height: auto; */

	z-index: 1000;
	label {
		position: relative;
		width: 100%;
		display: block;
		color: red($color: #000000);
		top: -8px;
		text-align: left;
	}
}

.swipe-up-select {
	max-width: 116px;
}

.swipe-up-button {
	color: #ff7d00;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 24px;
	border: unset;
	padding: 0;
	background-color: white;
	height: 40px;
	width: 100%;
	cursor: pointer;
	font-size: 20px;
	font-weight: 500;
	flex-direction: column;

	.material-icons {
		top: 4px;
		position: relative;
	}
}

.swipe-up-title-block {
	position: relative;
	top: -6px;
}

.swipe-up-button .black {
	color: #222;
}

.swipe-title {
	font-size: 22px;
	line-height: 1.25;
	text-align: center;
	margin: 16px 0;
	color: #ff4500;
}

.invalid-field {
	border: 1px solid red !important;
}

.swipe-cta {
	background-color: #ff4500;
	border: 1px solid #ff4500 !important;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-weight: bold;
	border-radius: 0;
	margin-bottom: 52px;
	margin-top: 12px;
	color: #fff !important;
	width: 100%;
	border: none;
}

.swipe-up-content p.text-left {
	text-align: left;
	margin-bottom: 12px;
}

.swipe-image {
	max-width: 160px;
	margin: 0 auto 12px;
}

.swipe-up-input {
	width: 100%;
	padding: 14px 16px;
	border: 1px solid #c9caca;
	margin-bottom: 24px;
}

.swipe-up-input-container {
	display: flex;
	width: 100%;
	column-gap: 20px;
}

@media (min-width: 940px) {
	.slide-message {
		display: none !important;
	}
}
</style>
