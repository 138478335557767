<template>
	<nav
		ref="mobileHeader"
		class="mobile-nav-link-container"
	>
		<div class="flex justify-between mobile-nav-header">
			<NuxtLink
				to="/"
				class="flex align-center"
			>
				<svg
					width="181"
					height="25"
					viewBox="0 0 181 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1.81989 6.90011H6.67284V20.3974H10.8433V6.90011H15.6963V3.18457H1.81989V6.90011Z"
						fill="#1E3A8A"
					/>
					<path
						d="M20.8526 3.18457H16.6821V20.3974H20.8526V3.18457Z"
						fill="#1E3A8A"
					/>
					<path
						d="M31.6201 13.0421L27.4496 3.18457H22.8241L22.2175 20.3974H26.7671V9.78155L30.1035 17.8951H33.1366L36.5488 9.78155V20.3974H41.0985L40.4919 3.18457H35.8664L31.6201 13.0421Z"
						fill="#1E3A8A"
					/>
					<path
						d="M57.9322 6.90011H62.7851V20.3974H66.9557V6.90011H71.8086V3.18457H57.9322V6.90011Z"
						fill="#1E3A8A"
					/>
					<path
						d="M83.6376 9.70573H76.8132V3.18457H72.7943V20.3974H76.8132V13.4213H83.6376V20.3974H87.5807V3.18457H83.6376V9.70573Z"
						fill="#1E3A8A"
					/>
					<path
						d="M100.471 3.18457L96.5283 9.47824L92.5853 3.18457H87.8082L94.4051 13.3454V20.3974H98.5757V13.3454L105.173 3.18457H100.471Z"
						fill="#1E3A8A"
					/>
					<path
						d="M114.954 9.47833C113.362 9.09919 111.921 8.79589 111.921 7.96178C111.921 7.12768 112.68 6.59689 113.969 6.59689C115.713 6.59689 117.381 7.20351 118.594 8.34092L118.822 8.5684L121.172 5.45948L120.945 5.30782C119.201 3.71545 117.002 2.88135 114.272 2.88135C110.481 2.88135 107.675 5.15617 107.675 8.26509C107.675 12.2081 111.39 13.118 114.044 13.7247C115.788 14.1038 117.229 14.4829 117.229 15.3929C117.229 16.3028 116.243 16.9094 114.651 16.9094C112.073 16.9094 110.405 15.5445 109.571 14.7104L109.343 14.4071L107.068 17.6677L107.22 17.8193C109.04 19.6392 111.466 20.5491 114.499 20.5491C118.822 20.5491 121.476 18.426 121.476 14.9379C121.476 10.9949 117.684 10.1608 114.954 9.47833Z"
						fill="#1E3A8A"
					/>
					<path
						d="M129.058 9.47824L125.115 3.18457H120.338L126.935 13.3454V20.3974H131.106V13.3454L137.703 3.18457H133.001L129.058 9.47824Z"
						fill="#1E3A8A"
					/>
					<path
						d="M152.489 3.18457H147.333L141.949 9.93321V3.18457H137.778V20.3974H141.949V15.0895L142.859 13.8762L147.788 20.3974H152.944L145.664 11.2223L152.489 3.18457Z"
						fill="#1E3A8A"
					/>
					<path
						d="M152.944 20.3974H165.304V16.606H157.114V13.4971H165.152V9.78155H157.114V6.90011H165.304V3.18457H152.944V20.3974Z"
						fill="#1E3A8A"
					/>
					<path
						d="M173.569 9.47833C171.977 9.09919 170.536 8.79589 170.536 7.96178C170.536 7.12768 171.294 6.59689 172.583 6.59689C174.327 6.59689 175.995 7.20351 177.209 8.34092L177.436 8.5684L179.787 5.45948L179.559 5.30782C177.815 3.71545 175.616 2.88135 172.886 2.88135C169.095 2.88135 166.289 5.15617 166.289 8.26509C166.289 12.2081 170.005 13.118 172.659 13.7247C174.403 14.1038 175.844 14.4829 175.844 15.3929C175.844 16.3028 174.858 16.9094 173.266 16.9094C170.687 16.9094 169.019 15.5445 168.185 14.7104L167.958 14.4071L165.683 17.6677L165.835 17.8193C167.654 19.6392 170.081 20.5491 173.114 20.5491C177.436 20.5491 180.09 18.426 180.09 14.9379C180.014 10.9949 176.299 10.1608 173.569 9.47833Z"
						fill="#1E3A8A"
					/>
					<path
						d="M57.4013 8.26518C57.0222 7.12777 56.4914 6.14201 55.8848 5.30791C55.2023 4.47381 54.3682 3.79136 53.3825 3.3364C52.3967 2.88143 51.2593 2.57812 50.0461 2.57812C48.8328 2.57812 47.6954 2.80561 46.7097 3.3364C45.7239 3.79136 44.8898 4.47381 44.2074 5.30791C43.5249 6.14201 42.9941 7.12777 42.6908 8.26518C42.3117 9.40259 42.16 10.6158 42.16 11.9049C42.16 13.194 42.3117 14.4072 42.6908 15.4688C43.07 16.6062 43.6008 17.592 44.2074 18.3502C44.8898 19.1843 45.7239 19.8668 46.7097 20.3217C47.6954 20.7767 48.8328 21.0042 50.0461 21.0042C51.2593 21.0042 52.3967 20.7767 53.3825 20.3217C54.3682 19.8668 55.2023 19.1843 55.8848 18.3502C56.5672 17.5161 57.098 16.5304 57.4013 15.4688C57.7805 14.3314 57.9321 13.194 57.9321 11.9049C58.008 10.6158 57.7805 9.40259 57.4013 8.26518ZM51.8659 16.9853C51.411 17.2886 50.8044 17.4403 50.0461 17.4403C49.2878 17.4403 48.6812 17.2886 48.2262 16.9853C47.6954 16.682 47.3163 16.2271 47.013 15.7721C46.7097 15.2413 46.4822 14.6347 46.3305 14.0281C46.1789 13.3456 46.1031 12.6632 46.1031 11.9807C46.1031 11.2224 46.1789 10.54 46.3305 9.85756C46.4822 9.17511 46.7097 8.56849 47.013 8.0377C47.3163 7.50691 47.7713 7.12777 48.2262 6.82446C48.6812 6.36949 49.2878 6.21784 50.0461 6.21784C50.8044 6.21784 51.411 6.3695 51.8659 6.6728C52.3967 6.97611 52.7759 7.43108 53.0792 7.88604C53.3825 8.41683 53.61 9.02345 53.7616 9.7059C53.9133 10.3883 53.9891 11.1466 53.9891 11.8291C53.9891 12.5115 53.9133 13.194 53.7616 13.8764C53.61 14.5589 53.3825 15.1655 53.0792 15.6204C52.8517 16.2271 52.3967 16.682 51.8659 16.9853Z"
						fill="#1E3A8A"
					/>
				</svg>
			</NuxtLink>

			<button
				class="mobile-menu-btn close xl:hidden"
				@click="toggle"
			>
				<svg
					width="21"
					height="22"
					viewBox="0 0 21 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1.4043 20.2886L19.7891 1.9038"
						stroke="black"
						stroke-width="2"
						stroke-linecap="round"
					/>
					<path
						d="M1.21094 1.71143L19.5957 20.0962"
						stroke="black"
						stroke-width="2"
						stroke-linecap="round"
					/>
				</svg>
			</button>
		</div>

		<div
			id="searchform"
			class="flex ml-auto mb-[16px]"
		>
			<input
				id="searchInput"
				type="text"
				name="q"
				placeholder="Search"
				class="search-field"
				@keypress.enter="onSearch"
			/>

			<button
				type="submit"
				class="mb-0"
				@click="onSearch"
			>
				<svg
					height="20"
					width="20"
					aria-hidden="true"
					focusable="false"
					data-prefix="fas"
					data-icon="search"
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 512 512"
					data-fa-i2svg=""
					class="svg-inline--fa fa-search fa-w-16"
				>
					<path
						fill="#fff"
						d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 
          322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 
          17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 
          0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
					/>
				</svg>
			</button>
		</div>

		<mobile-links-block :navigation="navigation" />

		<span class="mobile-live-btn">
			<AdButlerItem
				class="watch-live-ad"
				:ad-id="750284"
				v-if="showAds"
			/>
		</span>
	</nav>
</template>

<script setup>
import { AdButlerItem } from "@stockstotrade_com/stt-ts-shared-lib";

const { navigation, toggle } = defineProps({
	navigation: {
		type: Object,
		required: true
	},
	toggle: {
		type: Function,
		required: true
	}
});
const mobileHeader = ref();
const { current_hostname, hostname } = useRuntimeConfig().public;
const router = useRoute();
const showAds = ref(false);

const onSearch = () => {
	const searchInput = document.getElementById("searchInput").value;

	// Check if the search query is not empty
	if (searchInput.trim() !== "") {
		// Redirect to the search page with the query
		window.location.href = `/search/?q=${encodeURIComponent(searchInput)}`;
	}
};
onMounted(async () => {
	if (process.client) {
		showAds.value = window.adsAreWithUs;


		if(window.adsAreWithUs) {

			await setTimeout(() => {
				const adbutler_link = document.querySelectorAll(
				".watch-live-ad a"
				);
				
				if (adbutler_link) {

					adbutler_link.forEach(item => item.setAttribute("target", "_blank"));
				}		
			},250)
		}
	}
});

watch(
	() => router.name,
	(newPath, oldPath) => {
		if (process.client) {
			showAds.value = window.adsAreWithUs;
		}
	}
);

watch(mobileHeader, (newNav) => {
	if (process.client && newNav) {
		const only_link_block = newNav.querySelector(" .nav-link-block");

		only_link_block.addEventListener("click", () => {
			toggle();
		});

		const links = newNav.querySelectorAll(`.nav-submenu-link`);

		[...links].map((link) => {
			link.addEventListener(
				"click",
				(e) => {
					if (
						![
							new URL(current_hostname).origin,
							new URL(hostname).origin,
							window.location.origin
						].includes(new URL(link.href).origin)
					) {
						window.open(link.href, "_blank");
					}
					setTimeout(() => {
						toggle();
					}, 50);

					e.preventDefault();
				},
				{ pasive: true }
			);

			return null;
		});
	}
});
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

#searchform input {
	background-color: white;
	padding: 8px 12px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	border: 1px solid $primary-300;
	width: 100%;
	&:focus {
		outline-width: 0;
		outline-offset: 0;
		border: 1px solid $primary-700;
	}
}
#searchform button[type="submit"] {
	background-color: $primary-700;
	border: 1px solid $primary-700;

	font-weight: 600;
	color: white;
	min-width: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.search-bar {
	background-color: white;
	padding: 32px 0;
	margin-left: -16px;
	margin-right: -16px;
	height: 0;
	transition: all 900ms ease-in-out;
	overflow: hidden;
	display: none;
	width: 100%;
}
.search-bar.active {
	height: 104px;
	display: block;
}

.search-bar .container.py-0 {
	padding-top: 0;
	padding-bottom: 0;
}

.mobile-nav-header {
	padding: 17.5px 0;
	margin-bottom: 16px;
	position: sticky;
	top: 0;
	background-color: $primary-50;
	z-index: 1;
}

.mobile-menu {
	background-color: white;
	border-radius: 8px;
	padding: 8px;

	&.close {
		background-color: transparent;
	}
}

.header {
	background-color: $primary-900;

	.container {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	padding: 0 16px;
}

.nav-link-container {
	column-gap: 24px;
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px transparent;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 transparent;
	}
}

.blob.red {
	background: #ff5252;
	box-shadow: 0 0 0 0 #ff5252;
	animation: pulse-red 2s infinite;
	position: relative;
	left: -16px;
	@include breakpoint(lg) {
		left: 0;
	}
}

.blob {
	background: #000;
	border-radius: 50%;
	box-shadow: 0 0 0 0 #000;
	margin: 14px 8px 15px 12px;
	height: 10px;
	width: 10px;
	transform: scale(1);
	animation: pulse-black 2s infinite;
}

.nav-link-block:hover .nav-link {
	color: $secondary-500;
}

.nav-link-block .nav-submenu-container.active {
	display: block;
}

.nav-submenu-container {
	display: none;

	.container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-auto-flow: dense;
	}
}

.nav-menu-block {
	display: flex;
	flex-direction: column;
	column-gap: 16px;
}

.nav-submenu-container {
	position: absolute;
	width: 100%;
	left: 0;
	top: 59px;
	background: $primary-500;
	padding: 20px 0;
}

.nav-submenu-title {
	color: white;
	font-size: 18px;
	font-family: Rubik;
	margin-bottom: 12px;
}

.nav-submenu-link {
	font-size: 16px;
	font-weight: 400;
	padding: 8px 0;
	color: $primary-50;
	font-family: Rubik;

	&:hover {
		text-decoration: underline;
	}
}

.mobile-nav-link-container {
	position: fixed;
	width: 100vw;
	max-height: 100vh;
	left: 0;
	top: 0;
	z-index: 1001;
	padding: 0 16px;
	border-radius: 0px 0px 8px 8px;
	overflow-y: auto;
	background: #eff6ff;
	/* Drop Shadow */
	box-shadow: 1px 14px 20px 8px rgba(72, 73, 81, 0.1);
	padding-bottom: 16px;
	height: 100%;

	.mobile-live-btn {
		display: flex;
		gap: 32px 16px;
		justify-content: center;
		padding: 52px 0;
		border-top: 6px solid #eab308;
	}
}

.nav-link {
	color: #fff;
	font-family: Fira Sans;
	font-size: 24px;
	font-style: normal;
	height: 38px;
	font-weight: 400;
	line-height: 150%;
	/* 36px */
	letter-spacing: 1.44px;
	position: relative;

	&.live {
		border-radius: 8px;
		background: $error-700;
		display: flex;
		padding: 6px 16px;
		align-items: center;
		font-weight: 600;
		gap: 8px;
		line-height: 1;
		font-size: 16px;
		color: white;
		position: relative;
		left: -20px;
		@include breakpoint(lg) {
			left: 0;
		}
		&:hover {
			background: $error-500;
		}
	}
}
</style>
