<template>
	<CookieConsent />
	<ReadingProgressBar
		v-if="showProgressBar"
		:class="{
			'has-cookie-consent-banner': doestNotHaveCookie
		}"
	/>

	<mobile-menu
		v-if="showMenu"
		:navigation="navigation"
		:toggle="() => (showMenu = false)"
	/>

	<header
		ref="header"
		class="header"
		:class="{
			active: showSearch,
			'has-cookie-consent-banner': doestNotHaveCookie
		}"
	>
		<div class="container xl:container mx-auto">
			<NuxtLink to="/">
				<img
					src="/images/logo.svg"
					width="186"
					height="26"
					alt="timothy sykes logo"
				/>
			</NuxtLink>

			<button
				class="mobile-menu-btn xl:hidden"
				aria-label="Mobile menu button"
				@click="showMenu = true"
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M3 12H15M3 6H21M3 18H21"
						stroke="#667085"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</button>

			<nav
				class="nav-link-container hidden xl:flex items-center"
				role="navigation"
				aria-label="Main"
			>
				<div
					v-for="menu in navigation"
					:key="menu.ID"
					class="nav-link-block"
					tabindex="0"
				>
					<NuxtLink
						:to="menu.url"
						class="nav-link"
					>
						<span class="uppercase">
							{{ menu.post_title }}
						</span>
					</NuxtLink>

					<div
						v-if="menu.children && menu.children.length && showSubmenu"
						class="nav-submenu-container"
					>
						<div class="nav-container xl:container mx-auto grid grid-cols-4">
							<span
								ref="caret"
								class="caret"
							>
								<svg
									width="55"
									height="26"
									viewBox="0 0 55 26"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M25.9047 1.03597L2.8143 16.877C-1.23678 19.6562 0.73011 26 5.64287 26H49.7245C54.5221 26 56.5659 19.8988 52.7364 17.0089L31.7451 1.1679C30.0291 -0.127078 27.6774 -0.180202 25.9047 1.03597Z"
										fill="#EFF6FF"
									/>
								</svg>
							</span>
							<div
								v-for="(submenu, i) in menu.children"
								:key="i"
								class="nav-menu-block"
							>
								<Heading
									tag="h4"
									:text="submenu.post_title"
									class="nav-submenu-title"
								/>

								<NuxtLink
									v-for="item in submenu.children"
									:key="item.url"
									class="nav-submenu-link"
									:to="item.url"
									:target="checkPath(item.url)"
									@click="closeMenu"
								>
									{{ item.title }}
								</NuxtLink>
							</div>
						</div>
					</div>
				</div>

				<button
					ref="signInButton"
					class="h-[32px] w-[32px] flex items-center justify-center ml-[12px] mr-[12px]"
					@click="showSignInMenu"
					name="sign-in-button"
					aria-labelledby="sign-in-button"
				>
					<svg
						stroke="white"
						fill="none"
						stroke-width="0"
						viewBox="0 0 24 24"
						height="24"
						width="24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
							fill="white"
						></path>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z"
							fill="white"
						></path>
					</svg>
					<div
						v-if="showSignIn"
						class="absolute top-[32px] pt-[32px]"
						@mouseleave="showSignInMenu"
					>
						<div
							class="rounded-[12px] border-slate-200 border-solid border-[1px] pt-[8px] px-[16px] w-[155px] h-55 pb-[8px] bg-white flex flex-col"
						>
							<NuxtLink
								class="text-slate-700 py-[8px] nav-submenu-link"
								href="https://alerts.timothysykes.com"
								target="_blank"
							>
								Student Portal
							</NuxtLink>
						</div>
					</div>
				</button>
				<button
					aria-label="Search for post"
					class="h-[32px] w-[32px] flex items-center justify-center ml-[12px] mr-[12px]"
					@click="toggleSearch"
				>
					<svg
						height="24"
						width="24"
						aria-hidden="true"
						focusable="false"
						data-prefix="fas"
						data-icon="search"
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
						data-fa-i2svg=""
						class="svg-inline--fa fa-search fa-w-16"
					>
						<path
							fill="#fff"
							d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 
          322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 
          17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 
          0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
						/>
					</svg>
				</button>

				<span class="flex gap-3">
					<AdButlerItem
						class="watch-live-ad"
						:ad-id="750284"
						v-if="showAds"
					/>
				</span>
			</nav>
		</div>

		<!-- v-if="showSearch" -->

		<section
			v-if="showSearch"
			ref="searchform"
			:class="`search-bar ${showSearch ? 'active' : ''}`"
		>
			<Container class="mx-auto py-0">
				<div
					id="searchform"
					class="flex ml-auto mb-[16px]"
				>
					<input
						id="searchInput"
						ref="searchInput"
						type="text"
						name="q"
						placeholder="Search"
						class="search-field"
						@keypress.enter="onSearch"
					/>

					<button
						type="submit"
						class="mb-0"
						@click="onSearch"
					>
						<svg
							height="16"
							width="16"
							aria-hidden="true"
							focusable="false"
							data-prefix="fas"
							data-icon="search"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
							data-fa-i2svg=""
							class="svg-inline--fa fa-search fa-w-16"
						>
							<path
								fill="#fff"
								d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 
          322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 
          17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 
          0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
							/>
						</svg>
						Search
					</button>
				</div>
			</Container>
		</section>
	</header>

	<template v-if="router.path === '/' && store.isLoading">
		<Loading />
		<!-- <section class="faux-loading"></section> -->
	</template>
</template>

<script setup>
import { AdButlerItem } from "@stockstotrade_com/stt-ts-shared-lib";

const showAds = ref(false);

const showMenu = ref(false);
const showSubmenu = ref(true);
const showSignIn = ref(false);
const searchform = ref();
const searchInput = ref();
const header = ref();
const caret = ref();
const showSearch = ref(false);
const router = useRoute();
const showProgressBar = ref(false);
const { env } = useRuntimeConfig().public;
const doestNotHaveCookie = ref(false);
const onSearch = () => {
	const searchInput = document.getElementById("searchInput").value;

	// Check if the search query is not empty
	if (searchInput && searchInput.trim() !== "") {
		// Redirect to the search page with the query
		window.location.href = `/search/?q=${encodeURIComponent(searchInput)}`;
	}
};

// Watch for route changes and update showProgressBar

const { data } = await useAsyncData("navigation", async ({ $config }) => {
	const response = await $fetch(
		`${$config.public.hostname}wp-json/custom/v1/menu/28771`
	);

	if (response) {
		return response;
	}
});

const navigation = data.value;

const toggleHeaderClasses = (header, $route) => {
	if (!header) {
		return;
	}
	if (!["index", "widgetpage", "blog-slug"].includes($route.name)) {
		return;
	}

	const scrollY = window.scrollY;
	const isHome = $route.name === "index";

	if (scrollY > 10) {
		if (header) {
			header.classList.toggle(isHome ? "home" : "has-progress-bar", true);
			if (!isHome && header) {
				header.style.marginTop = 0;
			}
		}
	} else if (header) {
		header.classList.toggle(isHome ? "home" : "has-progress-bar", false);
		if (!isHome && header) {
			header.style.marginTop = "-10px";
		}
	}
};

onMounted(() => {
	if (process.client) {
		if (router.name === "index") {
			header.value.classList.add("home");
		}

		if (["blog-slug", "widgetpage"].includes(router.name)) {
			showProgressBar.value = true;
			if (header.value) {
				header.value.style.marginTop = "-10px";
			}
		}

		if (["blog-slug", "index", "widgetpage"].includes(router.name)) {
			window.addEventListener(
				"scroll",
				() => toggleHeaderClasses(header.value, router),
				{
					passive: true
				}
			);
		}
	}
});

onMounted(async () => {
	if (process.client) {
		const cookieThing = !checkCookieConsent();

		showAds.value = window.adsAreWithUs;

		doestNotHaveCookie.value = cookieThing;

		if (!checkCookieConsent()) {
			await setTimeout(() => {
				doestNotHaveCookie.value = false;
			}, 12000);
		}

		if(window.adsAreWithUs) {


			await setTimeout(() => {
				const adbutler_link = document.querySelectorAll(
				".watch-live-ad a"
				);
								
				if (adbutler_link) {

					adbutler_link.forEach(item => item.setAttribute("target", "_blank"));
				}		
			},250)
		
		}
	}
});
onBeforeUnmount(() => {
	if (process.client) {
		window.removeEventListener("scroll", () =>
			toggleHeaderClasses(header.value, router)
		);
	}
});

watch(
	() => router.path,
	(newPath, oldPath) => {
		if (process.client && newPath !== oldPath) {
			window.scrollTo(0, 0);
		}
	}
);

watch(
	() => router.name,
	(newPath, oldPath) => {
		if (process.client) {
			showAds.value = window.adsAreWithUs;

			if (newPath === "blog-slug" || newPath === "widgetpage") {
				if (header && header.value) {
					header.value.style.marginTop = "-10px";
				}
				showProgressBar.value = true;
			} else {
				showProgressBar.value = false;
			}

			if (newPath !== oldPath) {
				if (
					header.value &&
					header.value.classList.contains("home") &&
					newPath !== "index"
				) {
					header.value.classList.remove("home");
				}
				if (
					header.value &&
					header.value.classList.contains("has-progress-bar") &&
					newPath !== "blog-slug"
				) {
					header.value.classList.remove("has-progress-bar");
				}
				window.removeEventListener("scroll", () =>
					toggleHeaderClasses(header.value, router)
				);
			}

			if (newPath === "plans" || oldPath === "plans") {
				setTimeout(() => {
					showMenu.value = false;
				}, 1000);
			}

			if (
				newPath === "index" ||
				newPath === "blog-slug" ||
				newPath === "widgetpage"
			) {
				return window.addEventListener(
					"scroll",
					() => toggleHeaderClasses(header.value, router),
					{ passive: true }
				);
			}
		}
	}
);

watch(header, (newNav) => {
	try {
		if (process.client && newNav) {
			const links = document.querySelectorAll(`.nav-submenu-link`);

			[...links].map((link) => {
				link.addEventListener("click", (e) => {
					const linkToBlur = document.activeElement;

					if (
						linkToBlur.parentElement.parentElement &&
						linkToBlur.parentElement.parentElement.parentElement
					) {
						linkToBlur.parentElement.parentElement.parentElement.style.pointerEvents =
							"none";

						setTimeout(() => {
							linkToBlur.parentElement.parentElement.parentElement.style.pointerEvents =
								"auto";
						}, 50);
					}
					e.preventDefault();
				});

				return null;
			});
		}
	} catch (error) {
		if (env === "dev") {
			// eslint-disable-next-line no-console
			console.log("ERROR IN NAVIGATION", error);
		}
	}
});

const toggleSearch = () => {
	showSearch.value = !showSearch.value;
	setTimeout(() => searchInput.value.focus(), 200);

	if (showSignIn.value === true) {
		showSignIn.value = false;
	}
};

const showSignInMenu = () => {
	showSignIn.value = !showSignIn.value;

	if (showSearch.value === true) {
		showSearch.value = false;
	}
};

const closeMenu = () => {
	if (router.path === "/") {
		store.manualToggleLoading(true);
	}

	showSubmenu.value = !showSubmenu.value;
	if (showSubmenu.value !== true) {
		setTimeout(() => closeMenu(), 200);
	}
};

const checkPath = (url) => {
	const arr = [
		"https://www.instagram.com/timothysykes/",
		"https://www.facebook.com/timsykesfans/",
		"https://profit.ly/user/timothysykes/chart?aff=11983",
		"https://karmagawa.org/",
		"https://twitter.com/timothysykes",
		"https://www.youtube.com/channel/UCoSG43KFjTe0trsteSQ46Ng"
	];

	if (arr.includes(url)) {
		return "_blank";
	}
};
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

#searchform input {
	background-color: $primary-50;
	padding: 8px 12px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	border: 1px solid $primary-50;
	&:focus {
		outline-width: 0;
		outline-offset: 0;
		border: 1px solid $primary-700;
	}
}
#searchform button[type="submit"] {
	background-color: $primary-700;
	border: 1px solid $primary-700;

	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-weight: 600;
	color: white;
	min-width: 120px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}
.search-bar {
	background-color: white;
	padding: 32px 0;
	margin-left: -16px;
	margin-right: -16px;
	height: 0;
	transition: all 900ms ease-in-out;
	overflow: hidden;
	display: none;
}
.search-bar.active {
	height: 104px;
	display: block;
}

.search-bar .container.py-0 {
	padding-top: 0;
	padding-bottom: 0;
}
.mobile-menu-btn {
	background-color: white;
	border-radius: 8px;
	padding: 8px;

	&.close {
		background-color: transparent;
	}
}

.header.home.active {
	background-color: $primary-900;
}
.header {
	background-color: $primary-900;
	position: sticky;
	transition: all 100ms ease-in;
	z-index: 999;
	top: 0;

	&.home {
		background-color: $primary-900;
	}

	.container {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	padding: 0 16px;

	&.has-progress-bar {
		margin-top: 0;
		top: 10px;
		&.has-cookie-consent-banner {
			top: 90px;
		}
	}
}

.nav-link-block:focus .nav-link::before,
.nav-link-block:hover .nav-link::before {
	background-color: transparent;
	width: 100%;
	height: 32px;
	display: block;
	content: "";
	position: absolute;
	bottom: -32px;
}

.nav-link-block:focus .nav-link,
.nav-link-block:hover .nav-link {
	color: $secondary-500;
}

.nav-link-block:focus .nav-submenu-container,
.nav-link-block:hover .nav-submenu-container {
	display: block;
}

.nav-link-block:focus {
	outline: 1px solid $primary-300;
}

.nav-submenu-container {
	display: none;

	.container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-auto-flow: dense;
	}
}

.nav-menu-block {
	display: flex;
	flex-direction: column;
	column-gap: 16px;
}

.nav-submenu-container {
	position: absolute;
	width: 100%;
	left: 0;
	top: 59px;
	padding: 4px 0;
	z-index: 9999;
	max-width: 1200px;
	right: 0;
	margin: auto;
}

.nav-container {
	background-color: $primary-50;
	border-radius: 8px;
	filter: drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.12));
	padding: 32px;
}

.nav-submenu-title {
	color: $neutral-800;
	font-size: 20px;
	font-family: Rubik;
	margin-bottom: 12px;
	font-weight: 700;
}

.nav-submenu-link {
	font-size: 16px;
	font-weight: 400;
	padding: 8px 16px;
	line-height: 1.25;
	color: $neutral-800;
	display: inline-block;
	font-family: Rubik;
	border-left: 4px solid $primary-700;

	&:hover {
		text-decoration: underline;
		text-decoration-color: $secondary-500;
		font-weight: 600;
	}
}

span.caret {
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	width: 64px;
	margin: auto;

	top: -16px;

	svg {
		width: 64px;
	}
}

span.caret {
	@include breakpoint(lg) {
		display: block;
	}
}
</style>
