<template>
	<div :class="'slide-message-desktop' + (isActive ? ' active' : '')">
		<div class="swipe-up-header">
			<div class="swipe-up-header-container">
				<button
					class="slide-dismiss-button"
					@click="
						() => {
							onSwipeClose();
							hideModal();
							setCookie('mdsphd', 'true', 7);
						}
					"
				>
					<img
						src="/images/close_gray.svg"
						alt="close btn for slide-up"
						width="20"
						height="20"
						class="slide-up-close"
					/>
				</button>
				<div class="swipe-up-header-image-container">
					<img
						class="swipe-up-header-image"
						loading="lazy"
						width="150"
						height="248"
						src="/images/ts-swipe-photo.webp"
						alt="ts swipe photo"
					/>
				</div>

				<div class="swipe-up-desktop-title mb-0">
					Ready to reclaim your life through
					<span
						style="margin-left: 0"
						class="black"
					>
						trading profits?
					</span>
				</div>

				<NuxtLink
					to="/millionaire-challenge/?utm_source=blog&utm_medium=popup&utm_campaign=Qhdzor"
					target="_blank"
					class="swipe-cta-desktop"
				>
					BOOK A CALL
				</NuxtLink>
			</div>
		</div>
	</div>
</template>

<script setup>
// eslint-disable-next-line
defineProps(["onSwipeClose"]);

const isActive = ref(false);

const setCookie = (cname, cvalue, duration, duration_type = "D") => {
	const d = new Date();

	let mul = 24 * 60 * 60; // Days
	if (duration_type === "H") {
		// Hours
		mul = 60 * 60;
	} else if (duration_type === "M") {
		// Minutes
		mul = 60;
	}

	d.setTime(d.getTime() + duration * mul * 1000);
	const expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const hideModal = () => {
	document.querySelector(".slide-message-desktop").style.display = "none";
};
</script>

<style lang="scss">
#info-form {
	display: none;
	pointer-events: none;
}

.slide-message-desktop.active #info-form {
	display: block;
	pointer-events: initial;
}

.close-button {
	border: unset;
	background-color: transparent;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	bottom: 0;
	position: fixed;
	width: calc(100% - 40px);
	text-align: center;
	justify-content: center;
	color: #888;
	font-size: 16px;
	background-color: white;

	.material-icons {
		top: 0;
	}
}

.slide-message-desktop {
	background-color: white;
	border-top-left-radius: 32px;
	border-top-right-radius: 32px;
	padding: 12px 20px;
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 998;
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
	height: 66px;

	.slide-dismiss-button {
		display: flex;
		z-index: 10000;
		position: absolute;
		justify-content: center;
		align-items: center;
		margin: 0px -14px 0px;
		height: 50px;
		background: none;
		border: none;

		.material-icons {
			color: #c4c4c4;
			font-size: 20px;
		}
	}

	&.active {
		.slide-dismiss-button {
			display: none;
		}
	}
}

.slide-message-desktop:not(.active) .close-button {
	bottom: -60px;
}

.swipe-up-button {
	position: relative;
}

.swipe-up-button-invisible {
	position: absolute;
	height: 84px;
	width: 100%;
	content: "";
	display: block;
	top: -36px;
	left: 0;
	margin: auto;
	z-index: 1;
}

@keyframes slideDown {
	100% {
		height: 100%;
		max-height: 620px;
	}

	0% {
		height: 76px;
	}
}

@keyframes slideUp {
	0% {
		height: 76px;
	}

	100% {
		height: 100%;
		max-height: 620px;
	}
}

.slide-message-desktop.active .swipe-up-button {
	top: -60px;
	display: none;
}

.slide-message-desktop.active {
	height: auto;
	max-height: 620px;
	overflow-y: auto;
	transition: all 400ms ease;
	animation-name: slideUp;
	animation-duration: 0.25s;
	animation-timing-function: ease-in-out;
	/* min-height: 400px; */
	/* height: auto; */

	label {
		position: relative;
		width: 100%;
		display: block;
		color: red($color: #000000);
		top: -8px;
		text-align: left;
	}
}

.swipe-up-select {
	max-width: 116px;
}

.swipe-up-desktop-title {
	color: #ff7d00;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	border: unset;
	padding: 0;
	background-color: white;
	width: 100%;
	font-size: 24px;
	padding-left: 192px;
	font-weight: 500;
}

.swipe-up-title-block {
	position: relative;
	top: -6px;
}

.swipe-up-button .black {
	color: #222;
}

.swipe-title {
	font-size: 22px;
	line-height: 1.25;
	text-align: center;
	margin: 16px 0;
	color: #ff4500;
}

.invalid-field {
	border: 1px solid red !important;
}

.swipe-cta-desktop {
	background-color: #ff4500;
	border: 1px solid #ff4500 !important;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-weight: bold;
	border-radius: 0;
	margin-bottom: 0;
	margin-top: 0;
	color: #fff !important;
	width: 100%;
	max-width: 260px;
	border: none;
	font-size: 18px;
}

.swipe-up-content p.text-left {
	text-align: left;
	margin-bottom: 12px;
}

.swipe-image {
	max-width: 160px;
	margin: 0 auto 12px;
}

.swipe-up-input {
	width: 100%;
	padding: 14px 16px;
	border: 1px solid #c9caca;
}

.swipe-up-input-container {
	display: flex;
	width: 100%;
	column-gap: 20px;
}

@media (min-width: 940px) {
	.slide-message-desktop {
		// display: none !important;
		height: 80px;
		padding: 16px 20px;
		border-radius: 0;
	}
}

.swipe-up-header-container {
	max-width: 1024px;
	width: 100%;
	margin: auto;
	position: relative;
	display: flex;
	align-items: center;
}

.swipe-up-header-image-container {
	height: 200px;
	overflow: hidden;
	position: absolute;
	bottom: -24px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 200px;
	left: 0;
}

.swipe-up-header-image {
	z-index: 9;
	height: 248px;
	left: 0;
}

@media (max-width: 1023px) {
	.slide-message-desktop {
		padding: 16px 28px;
		border-radius: 0;
		height: 80px;
	}

	.swipe-up-header-image-container {
		display: none;
	}

	.swipe-up-desktop-title {
		padding-left: 48px;
		font-size: 22px;
	}
}
</style>
