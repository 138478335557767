import { default as _91_46_46_46widget_45page_93jgQkij5fnrMeta } from "/opt/build/repo/pages/[...widget-page].vue?macro=true";
import { default as affiliate_45disclosurejQpPAyHWoGMeta } from "/opt/build/repo/pages/affiliate-disclosure.vue?macro=true";
import { default as appKFB7xocJsDMeta } from "/opt/build/repo/pages/app.vue?macro=true";
import { default as artificial_45intelligence_45disclosureNrapQz3EeLMeta } from "/opt/build/repo/pages/artificial-intelligence-disclosure.vue?macro=true";
import { default as _91_46_46_46slug_93mvebfMgEVhMeta } from "/opt/build/repo/pages/blog/[...slug].vue?macro=true";
import { default as ben_45sturgilltcubMhmLWGMeta } from "/opt/build/repo/pages/blog/author/ben-sturgill.vue?macro=true";
import { default as bohen_45bot2qCxWHwnFEMeta } from "/opt/build/repo/pages/blog/author/bohen-bot.vue?macro=true";
import { default as bryce_45tuoheydtqplXYpphMeta } from "/opt/build/repo/pages/blog/author/bryce-tuohey.vue?macro=true";
import { default as ed_45weinbergHE8G8zqt94Meta } from "/opt/build/repo/pages/blog/author/ed-weinberg.vue?macro=true";
import { default as ellis_45hobbspL7nX1MZmkMeta } from "/opt/build/repo/pages/blog/author/ellis-hobbs.vue?macro=true";
import { default as friedrich_45odermann5GY8yTU8atMeta } from "/opt/build/repo/pages/blog/author/friedrich-odermann.vue?macro=true";
import { default as jack_45kelloggxpnMV1MdIpMeta } from "/opt/build/repo/pages/blog/author/jack-kellogg.vue?macro=true";
import { default as jeff_45zananirifHHPcSikh9Meta } from "/opt/build/repo/pages/blog/author/jeff-zananiri.vue?macro=true";
import { default as jordan_45s340NurCZEcMeta } from "/opt/build/repo/pages/blog/author/jordan-s.vue?macro=true";
import { default as matt_45monacoDODYi88YXvMeta } from "/opt/build/repo/pages/blog/author/matt-monaco.vue?macro=true";
import { default as stephanie_45thompsonx36q86iM86Meta } from "/opt/build/repo/pages/blog/author/stephanie-thompson.vue?macro=true";
import { default as tim_45bohenojpR4g4ti2Meta } from "/opt/build/repo/pages/blog/author/tim-bohen.vue?macro=true";
import { default as tim_45botEFIATneM4IMeta } from "/opt/build/repo/pages/blog/author/tim-bot.vue?macro=true";
import { default as tim_45sykesMNqMBZDXuGMeta } from "/opt/build/repo/pages/blog/author/tim-sykes.vue?macro=true";
import { default as timothysykes0X6vXOIAF4Meta } from "/opt/build/repo/pages/blog/author/timothysykes.vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46page_93Xvnjs6Uzq6Meta } from "/opt/build/repo/pages/blog/page/[...page].vue?macro=true";
import { default as _91_46_46_46slug_93kOwhYz7MwgMeta } from "/opt/build/repo/pages/blog/tim-video/[...slug].vue?macro=true";
import { default as _91_46_46_46page_93wAGOXwZzxXMeta } from "/opt/build/repo/pages/category/[...slug]/page/[...page].vue?macro=true";
import { default as _91_46_46_46slug_93AIO51TDLcSMeta } from "/opt/build/repo/pages/category/[...slug].vue?macro=true";
import { default as ccpa_45policyYpJAvphv6hMeta } from "/opt/build/repo/pages/ccpa-policy.vue?macro=true";
import { default as code_45of_45conductwy6imkl9NLMeta } from "/opt/build/repo/pages/code-of-conduct.vue?macro=true";
import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as cookie_45policyZcRIsfqfIvMeta } from "/opt/build/repo/pages/cookie-policy.vue?macro=true";
import { default as dmca_45takedown_45policy27iFkDoOzcMeta } from "/opt/build/repo/pages/dmca-takedown-policy.vue?macro=true";
import { default as earnings_45claims_45disclosure2X2si6LuMOMeta } from "/opt/build/repo/pages/earnings-claims-disclosure.vue?macro=true";
import { default as editorial_45guidelines0SNO1CxoBJMeta } from "/opt/build/repo/pages/editorial-guidelines.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as member_45termsTL4QbEIW4cMeta } from "/opt/build/repo/pages/member-terms.vue?macro=true";
import { default as millionaire_45challenge1VVydZ0hrYMeta } from "/opt/build/repo/pages/millionaire-challenge.vue?macro=true";
import { default as plansPed13sMCSsMeta } from "/opt/build/repo/pages/plans.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as indexfhz4qXOUc8Meta } from "/opt/build/repo/pages/reviews/index.vue?macro=true";
import { default as highest_45volume_45stockst92TyFAT1cMeta } from "/opt/build/repo/pages/scans/highest-volume-stocks.vue?macro=true";
import { default as indexVKYgSWQw3MMeta } from "/opt/build/repo/pages/scans/index.vue?macro=true";
import { default as stock_45gainersCp6dRkbgqKMeta } from "/opt/build/repo/pages/scans/stock-gainers.vue?macro=true";
import { default as stock_45losers7b77ckoi3bMeta } from "/opt/build/repo/pages/scans/stock-losers.vue?macro=true";
import { default as unusual_45volume_45stocksf612VZPTysMeta } from "/opt/build/repo/pages/scans/unusual-volume-stocks.vue?macro=true";
import { default as index3vmzcZu2ueMeta } from "/opt/build/repo/pages/search/index.vue?macro=true";
import { default as sitemapx1fjhjGxmTMeta } from "/opt/build/repo/pages/sitemap.vue?macro=true";
import { default as _91_46_46_46slug_93a8IqAOqDnhMeta } from "/opt/build/repo/pages/tag/[...slug].vue?macro=true";
import { default as terms_45of_45serviceIo0hlUNnc4Meta } from "/opt/build/repo/pages/terms-of-service.vue?macro=true";
import { default as _3_45stocks_45i_45struck_45gold_45in_45this_45volatile_45market51C7WJFt66Meta } from "/opt/build/repo/pages/tim-news/3-stocks-i-struck-gold-in-this-volatile-market.vue?macro=true";
import { default as heres_45what_45happened_45with_45inndT3mFE70mxuMeta } from "/opt/build/repo/pages/tim-news/heres-what-happened-with-innd.vue?macro=true";
import { default as my_45go_45to_45pattern_45for_45market_45dropsSZMs1ps2T1Meta } from "/opt/build/repo/pages/tim-news/my-go-to-pattern-for-market-drops.vue?macro=true";
import { default as my_45plan_45after_45a_45horrible_45trade9HbLQJSu2RMeta } from "/opt/build/repo/pages/tim-news/my-plan-after-a-horrible-trade.vue?macro=true";
import { default as my_45weekend_45planKxmPVj62PPMeta } from "/opt/build/repo/pages/tim-news/my-weekend-plan.vue?macro=true";
import { default as my_45worst_45loss_45of_452022COD2PYoubeMeta } from "/opt/build/repo/pages/tim-news/my-worst-loss-of-2022.vue?macro=true";
import { default as one_45meme_45stock_45perfect_45setupjWvhoEnD6DMeta } from "/opt/build/repo/pages/tim-news/one-meme-stock-perfect-setup.vue?macro=true";
import { default as these_454_45stocks_45are_45spiking_45quickRnGu7IApWqMeta } from "/opt/build/repo/pages/tim-news/these-4-stocks-are-spiking-quick.vue?macro=true";
import { default as these_45otc_45plays_45are_45working_45are_45working_45right_45now0VUKb9ffn7Meta } from "/opt/build/repo/pages/tim-news/these-otc-plays-are-working-are-working-right-now.vue?macro=true";
import { default as what_45meme_45stocks_45im_45watching14bM2aq6GFMeta } from "/opt/build/repo/pages/tim-news/what-meme-stocks-im-watching.vue?macro=true";
import { default as trading_45hw_451jfIweh0zWtMeta } from "/opt/build/repo/pages/trading-hw-1.vue?macro=true";
import { default as trading_45hw_452ODTz6B08KyMeta } from "/opt/build/repo/pages/trading-hw-2.vue?macro=true";
import { default as trading_45hw_453z2puixfHReMeta } from "/opt/build/repo/pages/trading-hw-3.vue?macro=true";
import { default as trading_45hw_454T2vYrjqMGJMeta } from "/opt/build/repo/pages/trading-hw-4.vue?macro=true";
import { default as trading_45hw_455hJjk3tjpIlMeta } from "/opt/build/repo/pages/trading-hw-5.vue?macro=true";
import { default as trading_45hw_45bonusKF70PQDgiwMeta } from "/opt/build/repo/pages/trading-hw-bonus.vue?macro=true";
import { default as workingjmMK1jN9QZMeta } from "/opt/build/repo/pages/working.vue?macro=true";
export default [
  {
    name: _91_46_46_46widget_45page_93jgQkij5fnrMeta?.name ?? "widgetpage",
    path: _91_46_46_46widget_45page_93jgQkij5fnrMeta?.path ?? "/:widgetpage(.*)*",
    meta: _91_46_46_46widget_45page_93jgQkij5fnrMeta || {},
    alias: _91_46_46_46widget_45page_93jgQkij5fnrMeta?.alias || [],
    redirect: _91_46_46_46widget_45page_93jgQkij5fnrMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[...widget-page].vue").then(m => m.default || m)
  },
  {
    name: affiliate_45disclosurejQpPAyHWoGMeta?.name ?? "affiliate-disclosure",
    path: affiliate_45disclosurejQpPAyHWoGMeta?.path ?? "/affiliate-disclosure",
    meta: affiliate_45disclosurejQpPAyHWoGMeta || {},
    alias: affiliate_45disclosurejQpPAyHWoGMeta?.alias || [],
    redirect: affiliate_45disclosurejQpPAyHWoGMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/affiliate-disclosure.vue").then(m => m.default || m)
  },
  {
    name: appKFB7xocJsDMeta?.name ?? "app",
    path: appKFB7xocJsDMeta?.path ?? "/app",
    meta: appKFB7xocJsDMeta || {},
    alias: appKFB7xocJsDMeta?.alias || [],
    redirect: appKFB7xocJsDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/app.vue").then(m => m.default || m)
  },
  {
    name: artificial_45intelligence_45disclosureNrapQz3EeLMeta?.name ?? "artificial-intelligence-disclosure",
    path: artificial_45intelligence_45disclosureNrapQz3EeLMeta?.path ?? "/artificial-intelligence-disclosure",
    meta: artificial_45intelligence_45disclosureNrapQz3EeLMeta || {},
    alias: artificial_45intelligence_45disclosureNrapQz3EeLMeta?.alias || [],
    redirect: artificial_45intelligence_45disclosureNrapQz3EeLMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/artificial-intelligence-disclosure.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mvebfMgEVhMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93mvebfMgEVhMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93mvebfMgEVhMeta || {},
    alias: _91_46_46_46slug_93mvebfMgEVhMeta?.alias || [],
    redirect: _91_46_46_46slug_93mvebfMgEVhMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: ben_45sturgilltcubMhmLWGMeta?.name ?? "blog-author-ben-sturgill",
    path: ben_45sturgilltcubMhmLWGMeta?.path ?? "/blog/author/ben-sturgill",
    meta: ben_45sturgilltcubMhmLWGMeta || {},
    alias: ben_45sturgilltcubMhmLWGMeta?.alias || [],
    redirect: ben_45sturgilltcubMhmLWGMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/ben-sturgill.vue").then(m => m.default || m)
  },
  {
    name: bohen_45bot2qCxWHwnFEMeta?.name ?? "blog-author-bohen-bot",
    path: bohen_45bot2qCxWHwnFEMeta?.path ?? "/blog/author/bohen-bot",
    meta: bohen_45bot2qCxWHwnFEMeta || {},
    alias: bohen_45bot2qCxWHwnFEMeta?.alias || [],
    redirect: bohen_45bot2qCxWHwnFEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/bohen-bot.vue").then(m => m.default || m)
  },
  {
    name: bryce_45tuoheydtqplXYpphMeta?.name ?? "blog-author-bryce-tuohey",
    path: bryce_45tuoheydtqplXYpphMeta?.path ?? "/blog/author/bryce-tuohey",
    meta: bryce_45tuoheydtqplXYpphMeta || {},
    alias: bryce_45tuoheydtqplXYpphMeta?.alias || [],
    redirect: bryce_45tuoheydtqplXYpphMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/bryce-tuohey.vue").then(m => m.default || m)
  },
  {
    name: ed_45weinbergHE8G8zqt94Meta?.name ?? "blog-author-ed-weinberg",
    path: ed_45weinbergHE8G8zqt94Meta?.path ?? "/blog/author/ed-weinberg",
    meta: ed_45weinbergHE8G8zqt94Meta || {},
    alias: ed_45weinbergHE8G8zqt94Meta?.alias || [],
    redirect: ed_45weinbergHE8G8zqt94Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/ed-weinberg.vue").then(m => m.default || m)
  },
  {
    name: ellis_45hobbspL7nX1MZmkMeta?.name ?? "blog-author-ellis-hobbs",
    path: ellis_45hobbspL7nX1MZmkMeta?.path ?? "/blog/author/ellis-hobbs",
    meta: ellis_45hobbspL7nX1MZmkMeta || {},
    alias: ellis_45hobbspL7nX1MZmkMeta?.alias || [],
    redirect: ellis_45hobbspL7nX1MZmkMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/ellis-hobbs.vue").then(m => m.default || m)
  },
  {
    name: friedrich_45odermann5GY8yTU8atMeta?.name ?? "blog-author-friedrich-odermann",
    path: friedrich_45odermann5GY8yTU8atMeta?.path ?? "/blog/author/friedrich-odermann",
    meta: friedrich_45odermann5GY8yTU8atMeta || {},
    alias: friedrich_45odermann5GY8yTU8atMeta?.alias || [],
    redirect: friedrich_45odermann5GY8yTU8atMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/friedrich-odermann.vue").then(m => m.default || m)
  },
  {
    name: jack_45kelloggxpnMV1MdIpMeta?.name ?? "blog-author-jack-kellogg",
    path: jack_45kelloggxpnMV1MdIpMeta?.path ?? "/blog/author/jack-kellogg",
    meta: jack_45kelloggxpnMV1MdIpMeta || {},
    alias: jack_45kelloggxpnMV1MdIpMeta?.alias || [],
    redirect: jack_45kelloggxpnMV1MdIpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/jack-kellogg.vue").then(m => m.default || m)
  },
  {
    name: jeff_45zananirifHHPcSikh9Meta?.name ?? "blog-author-jeff-zananiri",
    path: jeff_45zananirifHHPcSikh9Meta?.path ?? "/blog/author/jeff-zananiri",
    meta: jeff_45zananirifHHPcSikh9Meta || {},
    alias: jeff_45zananirifHHPcSikh9Meta?.alias || [],
    redirect: jeff_45zananirifHHPcSikh9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/jeff-zananiri.vue").then(m => m.default || m)
  },
  {
    name: jordan_45s340NurCZEcMeta?.name ?? "blog-author-jordan-s",
    path: jordan_45s340NurCZEcMeta?.path ?? "/blog/author/jordan-s",
    meta: jordan_45s340NurCZEcMeta || {},
    alias: jordan_45s340NurCZEcMeta?.alias || [],
    redirect: jordan_45s340NurCZEcMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/jordan-s.vue").then(m => m.default || m)
  },
  {
    name: matt_45monacoDODYi88YXvMeta?.name ?? "blog-author-matt-monaco",
    path: matt_45monacoDODYi88YXvMeta?.path ?? "/blog/author/matt-monaco",
    meta: matt_45monacoDODYi88YXvMeta || {},
    alias: matt_45monacoDODYi88YXvMeta?.alias || [],
    redirect: matt_45monacoDODYi88YXvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/matt-monaco.vue").then(m => m.default || m)
  },
  {
    name: stephanie_45thompsonx36q86iM86Meta?.name ?? "blog-author-stephanie-thompson",
    path: stephanie_45thompsonx36q86iM86Meta?.path ?? "/blog/author/stephanie-thompson",
    meta: stephanie_45thompsonx36q86iM86Meta || {},
    alias: stephanie_45thompsonx36q86iM86Meta?.alias || [],
    redirect: stephanie_45thompsonx36q86iM86Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/stephanie-thompson.vue").then(m => m.default || m)
  },
  {
    name: tim_45bohenojpR4g4ti2Meta?.name ?? "blog-author-tim-bohen",
    path: tim_45bohenojpR4g4ti2Meta?.path ?? "/blog/author/tim-bohen",
    meta: tim_45bohenojpR4g4ti2Meta || {},
    alias: tim_45bohenojpR4g4ti2Meta?.alias || [],
    redirect: tim_45bohenojpR4g4ti2Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/tim-bohen.vue").then(m => m.default || m)
  },
  {
    name: tim_45botEFIATneM4IMeta?.name ?? "blog-author-tim-bot",
    path: tim_45botEFIATneM4IMeta?.path ?? "/blog/author/tim-bot",
    meta: tim_45botEFIATneM4IMeta || {},
    alias: tim_45botEFIATneM4IMeta?.alias || [],
    redirect: tim_45botEFIATneM4IMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/tim-bot.vue").then(m => m.default || m)
  },
  {
    name: tim_45sykesMNqMBZDXuGMeta?.name ?? "blog-author-tim-sykes",
    path: tim_45sykesMNqMBZDXuGMeta?.path ?? "/blog/author/tim-sykes",
    meta: tim_45sykesMNqMBZDXuGMeta || {},
    alias: tim_45sykesMNqMBZDXuGMeta?.alias || [],
    redirect: tim_45sykesMNqMBZDXuGMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/tim-sykes.vue").then(m => m.default || m)
  },
  {
    name: timothysykes0X6vXOIAF4Meta?.name ?? "blog-author-timothysykes",
    path: timothysykes0X6vXOIAF4Meta?.path ?? "/blog/author/timothysykes",
    meta: timothysykes0X6vXOIAF4Meta || {},
    alias: timothysykes0X6vXOIAF4Meta?.alias || [],
    redirect: timothysykes0X6vXOIAF4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/author/timothysykes.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog",
    path: indexmFsYN4t0XfMeta?.path ?? "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93Xvnjs6Uzq6Meta?.name ?? "blog-page-page",
    path: _91_46_46_46page_93Xvnjs6Uzq6Meta?.path ?? "/blog/page/:page(.*)*",
    meta: _91_46_46_46page_93Xvnjs6Uzq6Meta || {},
    alias: _91_46_46_46page_93Xvnjs6Uzq6Meta?.alias || [],
    redirect: _91_46_46_46page_93Xvnjs6Uzq6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/page/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93kOwhYz7MwgMeta?.name ?? "blog-tim-video-slug",
    path: _91_46_46_46slug_93kOwhYz7MwgMeta?.path ?? "/blog/tim-video/:slug(.*)*",
    meta: _91_46_46_46slug_93kOwhYz7MwgMeta || {},
    alias: _91_46_46_46slug_93kOwhYz7MwgMeta?.alias || [],
    redirect: _91_46_46_46slug_93kOwhYz7MwgMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/blog/tim-video/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93AIO51TDLcSMeta?.name ?? "category-slug",
    path: _91_46_46_46slug_93AIO51TDLcSMeta?.path ?? "/category/:slug(.*)*",
    children: [
  {
    name: _91_46_46_46page_93wAGOXwZzxXMeta?.name ?? "category-slug-page-page",
    path: _91_46_46_46page_93wAGOXwZzxXMeta?.path ?? "page/:page(.*)*",
    meta: _91_46_46_46page_93wAGOXwZzxXMeta || {},
    alias: _91_46_46_46page_93wAGOXwZzxXMeta?.alias || [],
    redirect: _91_46_46_46page_93wAGOXwZzxXMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/category/[...slug]/page/[...page].vue").then(m => m.default || m)
  }
],
    meta: _91_46_46_46slug_93AIO51TDLcSMeta || {},
    alias: _91_46_46_46slug_93AIO51TDLcSMeta?.alias || [],
    redirect: _91_46_46_46slug_93AIO51TDLcSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/category/[...slug].vue").then(m => m.default || m)
  },
  {
    name: ccpa_45policyYpJAvphv6hMeta?.name ?? "ccpa-policy",
    path: ccpa_45policyYpJAvphv6hMeta?.path ?? "/ccpa-policy",
    meta: ccpa_45policyYpJAvphv6hMeta || {},
    alias: ccpa_45policyYpJAvphv6hMeta?.alias || [],
    redirect: ccpa_45policyYpJAvphv6hMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/ccpa-policy.vue").then(m => m.default || m)
  },
  {
    name: code_45of_45conductwy6imkl9NLMeta?.name ?? "code-of-conduct",
    path: code_45of_45conductwy6imkl9NLMeta?.path ?? "/code-of-conduct",
    meta: code_45of_45conductwy6imkl9NLMeta || {},
    alias: code_45of_45conductwy6imkl9NLMeta?.alias || [],
    redirect: code_45of_45conductwy6imkl9NLMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact",
    path: contactNfojXvLlAkMeta?.path ?? "/contact",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyZcRIsfqfIvMeta?.name ?? "cookie-policy",
    path: cookie_45policyZcRIsfqfIvMeta?.path ?? "/cookie-policy",
    meta: cookie_45policyZcRIsfqfIvMeta || {},
    alias: cookie_45policyZcRIsfqfIvMeta?.alias || [],
    redirect: cookie_45policyZcRIsfqfIvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: dmca_45takedown_45policy27iFkDoOzcMeta?.name ?? "dmca-takedown-policy",
    path: dmca_45takedown_45policy27iFkDoOzcMeta?.path ?? "/dmca-takedown-policy",
    meta: dmca_45takedown_45policy27iFkDoOzcMeta || {},
    alias: dmca_45takedown_45policy27iFkDoOzcMeta?.alias || [],
    redirect: dmca_45takedown_45policy27iFkDoOzcMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dmca-takedown-policy.vue").then(m => m.default || m)
  },
  {
    name: earnings_45claims_45disclosure2X2si6LuMOMeta?.name ?? "earnings-claims-disclosure",
    path: earnings_45claims_45disclosure2X2si6LuMOMeta?.path ?? "/earnings-claims-disclosure",
    meta: earnings_45claims_45disclosure2X2si6LuMOMeta || {},
    alias: earnings_45claims_45disclosure2X2si6LuMOMeta?.alias || [],
    redirect: earnings_45claims_45disclosure2X2si6LuMOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/earnings-claims-disclosure.vue").then(m => m.default || m)
  },
  {
    name: editorial_45guidelines0SNO1CxoBJMeta?.name ?? "editorial-guidelines",
    path: editorial_45guidelines0SNO1CxoBJMeta?.path ?? "/editorial-guidelines",
    meta: editorial_45guidelines0SNO1CxoBJMeta || {},
    alias: editorial_45guidelines0SNO1CxoBJMeta?.alias || [],
    redirect: editorial_45guidelines0SNO1CxoBJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/editorial-guidelines.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: member_45termsTL4QbEIW4cMeta?.name ?? "member-terms",
    path: member_45termsTL4QbEIW4cMeta?.path ?? "/member-terms",
    meta: member_45termsTL4QbEIW4cMeta || {},
    alias: member_45termsTL4QbEIW4cMeta?.alias || [],
    redirect: member_45termsTL4QbEIW4cMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/member-terms.vue").then(m => m.default || m)
  },
  {
    name: millionaire_45challenge1VVydZ0hrYMeta?.name ?? "millionaire-challenge",
    path: millionaire_45challenge1VVydZ0hrYMeta?.path ?? "/millionaire-challenge",
    meta: millionaire_45challenge1VVydZ0hrYMeta || {},
    alias: millionaire_45challenge1VVydZ0hrYMeta?.alias || [],
    redirect: millionaire_45challenge1VVydZ0hrYMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/millionaire-challenge.vue").then(m => m.default || m)
  },
  {
    name: plansPed13sMCSsMeta?.name ?? "plans",
    path: plansPed13sMCSsMeta?.path ?? "/plans",
    meta: plansPed13sMCSsMeta || {},
    alias: plansPed13sMCSsMeta?.alias || [],
    redirect: plansPed13sMCSsMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQXTlhFuZ55Meta?.name ?? "privacy-policy",
    path: privacy_45policyQXTlhFuZ55Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    alias: privacy_45policyQXTlhFuZ55Meta?.alias || [],
    redirect: privacy_45policyQXTlhFuZ55Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexfhz4qXOUc8Meta?.name ?? "reviews",
    path: indexfhz4qXOUc8Meta?.path ?? "/reviews",
    meta: indexfhz4qXOUc8Meta || {},
    alias: indexfhz4qXOUc8Meta?.alias || [],
    redirect: indexfhz4qXOUc8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: highest_45volume_45stockst92TyFAT1cMeta?.name ?? "scans-highest-volume-stocks",
    path: highest_45volume_45stockst92TyFAT1cMeta?.path ?? "/scans/highest-volume-stocks",
    meta: highest_45volume_45stockst92TyFAT1cMeta || {},
    alias: highest_45volume_45stockst92TyFAT1cMeta?.alias || [],
    redirect: highest_45volume_45stockst92TyFAT1cMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/scans/highest-volume-stocks.vue").then(m => m.default || m)
  },
  {
    name: indexVKYgSWQw3MMeta?.name ?? "scans",
    path: indexVKYgSWQw3MMeta?.path ?? "/scans",
    meta: indexVKYgSWQw3MMeta || {},
    alias: indexVKYgSWQw3MMeta?.alias || [],
    redirect: indexVKYgSWQw3MMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/scans/index.vue").then(m => m.default || m)
  },
  {
    name: stock_45gainersCp6dRkbgqKMeta?.name ?? "scans-stock-gainers",
    path: stock_45gainersCp6dRkbgqKMeta?.path ?? "/scans/stock-gainers",
    meta: stock_45gainersCp6dRkbgqKMeta || {},
    alias: stock_45gainersCp6dRkbgqKMeta?.alias || [],
    redirect: stock_45gainersCp6dRkbgqKMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/scans/stock-gainers.vue").then(m => m.default || m)
  },
  {
    name: stock_45losers7b77ckoi3bMeta?.name ?? "scans-stock-losers",
    path: stock_45losers7b77ckoi3bMeta?.path ?? "/scans/stock-losers",
    meta: stock_45losers7b77ckoi3bMeta || {},
    alias: stock_45losers7b77ckoi3bMeta?.alias || [],
    redirect: stock_45losers7b77ckoi3bMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/scans/stock-losers.vue").then(m => m.default || m)
  },
  {
    name: unusual_45volume_45stocksf612VZPTysMeta?.name ?? "scans-unusual-volume-stocks",
    path: unusual_45volume_45stocksf612VZPTysMeta?.path ?? "/scans/unusual-volume-stocks",
    meta: unusual_45volume_45stocksf612VZPTysMeta || {},
    alias: unusual_45volume_45stocksf612VZPTysMeta?.alias || [],
    redirect: unusual_45volume_45stocksf612VZPTysMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/scans/unusual-volume-stocks.vue").then(m => m.default || m)
  },
  {
    name: index3vmzcZu2ueMeta?.name ?? "search",
    path: index3vmzcZu2ueMeta?.path ?? "/search",
    meta: index3vmzcZu2ueMeta || {},
    alias: index3vmzcZu2ueMeta?.alias || [],
    redirect: index3vmzcZu2ueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: sitemapx1fjhjGxmTMeta?.name ?? "sitemap",
    path: sitemapx1fjhjGxmTMeta?.path ?? "/sitemap",
    meta: sitemapx1fjhjGxmTMeta || {},
    alias: sitemapx1fjhjGxmTMeta?.alias || [],
    redirect: sitemapx1fjhjGxmTMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93a8IqAOqDnhMeta?.name ?? "tag-slug",
    path: _91_46_46_46slug_93a8IqAOqDnhMeta?.path ?? "/tag/:slug(.*)*",
    meta: _91_46_46_46slug_93a8IqAOqDnhMeta || {},
    alias: _91_46_46_46slug_93a8IqAOqDnhMeta?.alias || [],
    redirect: _91_46_46_46slug_93a8IqAOqDnhMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tag/[...slug].vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceIo0hlUNnc4Meta?.name ?? "terms-of-service",
    path: terms_45of_45serviceIo0hlUNnc4Meta?.path ?? "/terms-of-service",
    meta: terms_45of_45serviceIo0hlUNnc4Meta || {},
    alias: terms_45of_45serviceIo0hlUNnc4Meta?.alias || [],
    redirect: terms_45of_45serviceIo0hlUNnc4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: _3_45stocks_45i_45struck_45gold_45in_45this_45volatile_45market51C7WJFt66Meta?.name ?? "tim-news-3-stocks-i-struck-gold-in-this-volatile-market",
    path: _3_45stocks_45i_45struck_45gold_45in_45this_45volatile_45market51C7WJFt66Meta?.path ?? "/tim-news/3-stocks-i-struck-gold-in-this-volatile-market",
    meta: _3_45stocks_45i_45struck_45gold_45in_45this_45volatile_45market51C7WJFt66Meta || {},
    alias: _3_45stocks_45i_45struck_45gold_45in_45this_45volatile_45market51C7WJFt66Meta?.alias || [],
    redirect: _3_45stocks_45i_45struck_45gold_45in_45this_45volatile_45market51C7WJFt66Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tim-news/3-stocks-i-struck-gold-in-this-volatile-market.vue").then(m => m.default || m)
  },
  {
    name: heres_45what_45happened_45with_45inndT3mFE70mxuMeta?.name ?? "tim-news-heres-what-happened-with-innd",
    path: heres_45what_45happened_45with_45inndT3mFE70mxuMeta?.path ?? "/tim-news/heres-what-happened-with-innd",
    meta: heres_45what_45happened_45with_45inndT3mFE70mxuMeta || {},
    alias: heres_45what_45happened_45with_45inndT3mFE70mxuMeta?.alias || [],
    redirect: heres_45what_45happened_45with_45inndT3mFE70mxuMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tim-news/heres-what-happened-with-innd.vue").then(m => m.default || m)
  },
  {
    name: my_45go_45to_45pattern_45for_45market_45dropsSZMs1ps2T1Meta?.name ?? "tim-news-my-go-to-pattern-for-market-drops",
    path: my_45go_45to_45pattern_45for_45market_45dropsSZMs1ps2T1Meta?.path ?? "/tim-news/my-go-to-pattern-for-market-drops",
    meta: my_45go_45to_45pattern_45for_45market_45dropsSZMs1ps2T1Meta || {},
    alias: my_45go_45to_45pattern_45for_45market_45dropsSZMs1ps2T1Meta?.alias || [],
    redirect: my_45go_45to_45pattern_45for_45market_45dropsSZMs1ps2T1Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tim-news/my-go-to-pattern-for-market-drops.vue").then(m => m.default || m)
  },
  {
    name: my_45plan_45after_45a_45horrible_45trade9HbLQJSu2RMeta?.name ?? "tim-news-my-plan-after-a-horrible-trade",
    path: my_45plan_45after_45a_45horrible_45trade9HbLQJSu2RMeta?.path ?? "/tim-news/my-plan-after-a-horrible-trade",
    meta: my_45plan_45after_45a_45horrible_45trade9HbLQJSu2RMeta || {},
    alias: my_45plan_45after_45a_45horrible_45trade9HbLQJSu2RMeta?.alias || [],
    redirect: my_45plan_45after_45a_45horrible_45trade9HbLQJSu2RMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tim-news/my-plan-after-a-horrible-trade.vue").then(m => m.default || m)
  },
  {
    name: my_45weekend_45planKxmPVj62PPMeta?.name ?? "tim-news-my-weekend-plan",
    path: my_45weekend_45planKxmPVj62PPMeta?.path ?? "/tim-news/my-weekend-plan",
    meta: my_45weekend_45planKxmPVj62PPMeta || {},
    alias: my_45weekend_45planKxmPVj62PPMeta?.alias || [],
    redirect: my_45weekend_45planKxmPVj62PPMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tim-news/my-weekend-plan.vue").then(m => m.default || m)
  },
  {
    name: my_45worst_45loss_45of_452022COD2PYoubeMeta?.name ?? "tim-news-my-worst-loss-of-2022",
    path: my_45worst_45loss_45of_452022COD2PYoubeMeta?.path ?? "/tim-news/my-worst-loss-of-2022",
    meta: my_45worst_45loss_45of_452022COD2PYoubeMeta || {},
    alias: my_45worst_45loss_45of_452022COD2PYoubeMeta?.alias || [],
    redirect: my_45worst_45loss_45of_452022COD2PYoubeMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tim-news/my-worst-loss-of-2022.vue").then(m => m.default || m)
  },
  {
    name: one_45meme_45stock_45perfect_45setupjWvhoEnD6DMeta?.name ?? "tim-news-one-meme-stock-perfect-setup",
    path: one_45meme_45stock_45perfect_45setupjWvhoEnD6DMeta?.path ?? "/tim-news/one-meme-stock-perfect-setup",
    meta: one_45meme_45stock_45perfect_45setupjWvhoEnD6DMeta || {},
    alias: one_45meme_45stock_45perfect_45setupjWvhoEnD6DMeta?.alias || [],
    redirect: one_45meme_45stock_45perfect_45setupjWvhoEnD6DMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tim-news/one-meme-stock-perfect-setup.vue").then(m => m.default || m)
  },
  {
    name: these_454_45stocks_45are_45spiking_45quickRnGu7IApWqMeta?.name ?? "tim-news-these-4-stocks-are-spiking-quick",
    path: these_454_45stocks_45are_45spiking_45quickRnGu7IApWqMeta?.path ?? "/tim-news/these-4-stocks-are-spiking-quick",
    meta: these_454_45stocks_45are_45spiking_45quickRnGu7IApWqMeta || {},
    alias: these_454_45stocks_45are_45spiking_45quickRnGu7IApWqMeta?.alias || [],
    redirect: these_454_45stocks_45are_45spiking_45quickRnGu7IApWqMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tim-news/these-4-stocks-are-spiking-quick.vue").then(m => m.default || m)
  },
  {
    name: these_45otc_45plays_45are_45working_45are_45working_45right_45now0VUKb9ffn7Meta?.name ?? "tim-news-these-otc-plays-are-working-are-working-right-now",
    path: these_45otc_45plays_45are_45working_45are_45working_45right_45now0VUKb9ffn7Meta?.path ?? "/tim-news/these-otc-plays-are-working-are-working-right-now",
    meta: these_45otc_45plays_45are_45working_45are_45working_45right_45now0VUKb9ffn7Meta || {},
    alias: these_45otc_45plays_45are_45working_45are_45working_45right_45now0VUKb9ffn7Meta?.alias || [],
    redirect: these_45otc_45plays_45are_45working_45are_45working_45right_45now0VUKb9ffn7Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tim-news/these-otc-plays-are-working-are-working-right-now.vue").then(m => m.default || m)
  },
  {
    name: what_45meme_45stocks_45im_45watching14bM2aq6GFMeta?.name ?? "tim-news-what-meme-stocks-im-watching",
    path: what_45meme_45stocks_45im_45watching14bM2aq6GFMeta?.path ?? "/tim-news/what-meme-stocks-im-watching",
    meta: what_45meme_45stocks_45im_45watching14bM2aq6GFMeta || {},
    alias: what_45meme_45stocks_45im_45watching14bM2aq6GFMeta?.alias || [],
    redirect: what_45meme_45stocks_45im_45watching14bM2aq6GFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tim-news/what-meme-stocks-im-watching.vue").then(m => m.default || m)
  },
  {
    name: trading_45hw_451jfIweh0zWtMeta?.name ?? "trading-hw-1",
    path: trading_45hw_451jfIweh0zWtMeta?.path ?? "/trading-hw-1",
    meta: trading_45hw_451jfIweh0zWtMeta || {},
    alias: trading_45hw_451jfIweh0zWtMeta?.alias || [],
    redirect: trading_45hw_451jfIweh0zWtMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trading-hw-1.vue").then(m => m.default || m)
  },
  {
    name: trading_45hw_452ODTz6B08KyMeta?.name ?? "trading-hw-2",
    path: trading_45hw_452ODTz6B08KyMeta?.path ?? "/trading-hw-2",
    meta: trading_45hw_452ODTz6B08KyMeta || {},
    alias: trading_45hw_452ODTz6B08KyMeta?.alias || [],
    redirect: trading_45hw_452ODTz6B08KyMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trading-hw-2.vue").then(m => m.default || m)
  },
  {
    name: trading_45hw_453z2puixfHReMeta?.name ?? "trading-hw-3",
    path: trading_45hw_453z2puixfHReMeta?.path ?? "/trading-hw-3",
    meta: trading_45hw_453z2puixfHReMeta || {},
    alias: trading_45hw_453z2puixfHReMeta?.alias || [],
    redirect: trading_45hw_453z2puixfHReMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trading-hw-3.vue").then(m => m.default || m)
  },
  {
    name: trading_45hw_454T2vYrjqMGJMeta?.name ?? "trading-hw-4",
    path: trading_45hw_454T2vYrjqMGJMeta?.path ?? "/trading-hw-4",
    meta: trading_45hw_454T2vYrjqMGJMeta || {},
    alias: trading_45hw_454T2vYrjqMGJMeta?.alias || [],
    redirect: trading_45hw_454T2vYrjqMGJMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trading-hw-4.vue").then(m => m.default || m)
  },
  {
    name: trading_45hw_455hJjk3tjpIlMeta?.name ?? "trading-hw-5",
    path: trading_45hw_455hJjk3tjpIlMeta?.path ?? "/trading-hw-5",
    meta: trading_45hw_455hJjk3tjpIlMeta || {},
    alias: trading_45hw_455hJjk3tjpIlMeta?.alias || [],
    redirect: trading_45hw_455hJjk3tjpIlMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trading-hw-5.vue").then(m => m.default || m)
  },
  {
    name: trading_45hw_45bonusKF70PQDgiwMeta?.name ?? "trading-hw-bonus",
    path: trading_45hw_45bonusKF70PQDgiwMeta?.path ?? "/trading-hw-bonus",
    meta: trading_45hw_45bonusKF70PQDgiwMeta || {},
    alias: trading_45hw_45bonusKF70PQDgiwMeta?.alias || [],
    redirect: trading_45hw_45bonusKF70PQDgiwMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trading-hw-bonus.vue").then(m => m.default || m)
  },
  {
    name: workingjmMK1jN9QZMeta?.name ?? "working",
    path: workingjmMK1jN9QZMeta?.path ?? "/working",
    meta: workingjmMK1jN9QZMeta || {},
    alias: workingjmMK1jN9QZMeta?.alias || [],
    redirect: workingjmMK1jN9QZMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/working.vue").then(m => m.default || m)
  }
]