import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function formatDate(date) {
	return dayjs(date).format("MMMM D. YYYY");
}

function formatPostDate(inputDate) {
	return dayjs
		.tz(inputDate, "YYYY-MM-DD HH:mm:ss", "America/Los_Angeles")
		.format("M/D/YYYY");
}

function formatCommentDate(inputDate) {
	return dayjs
		.tz(inputDate, "YYYY-MM-DD HH:mm:ss", "America/Los_Angeles")
		.format("MMM. DD, YYYY [at] h:mm a");
}
const dateFormat = (date) => {
	return dayjs(date).format("MMMM D, YYYY");
};

const isResolution = (breakpoint) => {
	if (breakpoint == "sm" && window.innerWidth < 768) return true;

	return false;
};

const truncateText = (text, maxLength) => {
	if (text.length <= maxLength) {
		return text;
	} else {
		return text.substring(0, maxLength) + "...";
	}
};
export {
	formatCommentDate,
	dateFormat,
	isResolution,
	truncateText,
	formatPostDate
};
