<template>
	<section
		class="cookie-consent"
		v-if="!hideBanner"
	>
		<Container class="mx-auto cookie-consent-body gap-[16px] relative !px-0">
			<p class="text-sm lg:text-base white-space-nowrap max-lg:text-left">
				We use cookies to provide you with the best online experience. If you
				continue browsing, we consider that you accept our
				<span class="inline-flex">
					<NuxtLink
						to="/cookie-policy"
						class="underline"
						v-html="'Cookie Policy'"
					/>
					,
				</span>
				and also agree to the terms of our

				<span>
					<NuxtLink
						to="/privacy-policy"
						class="underline"
						v-html="'Privacy Policy'"
					/>
					and
					<NuxtLink
						to="/terms-of-service"
						class="underline"
						v-html="'Terms of Use.'"
					/>
				</span>
			</p>
		</Container>
	</section>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

const hideBanner = ref(true);
const consentTimer = ref(null);

function updateCookie(name, value, daysToExpire) {
	const expirationDate = new Date();
	expirationDate.setTime(
		expirationDate.getTime() + daysToExpire * 24 * 60 * 60 * 1000
	);
	const expires = `expires=${expirationDate.toUTCString()}`;
	document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; path=/`;
}

const cookieMethod = async () => {
	const cookieThing = checkCookieConsent();

	if (!cookieThing) {
		hideBanner.value = false;

		await setTimeout(() => {
			updateCookie("cookieConsent", true, 182);
			hideBanner.value = true;
		}, 12000);
	}
};
onMounted(async () => {
	if (process.client) {
		await cookieMethod();
	}
});

watch(
	() => hideBanner.value,
	() => {
		if (hideBanner.value) {
			clearTimeout(consentTimer.value);
		}
	}
);
</script>
<style lang="scss">
@import "~/assets/utils.scss";

.okay-btn {
	border-radius: 8px;
	background: $primary-50;
	border: 3px solid $primary-50;
	color: $primary-900;
	text-align: center;
	font-family: Fira Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 25px; /* 138.889% */
	padding: 2px 12px;

	&.secondary {
		background: $primary-900;
		border: 3px solid $primary-50;
		color: $primary-50;
	}

	@include breakpoint(md) {
		padding: 8px 24px;
		font-size: 18px;
	}
}

.cookie-consent {
	background-color: #1e3a8a;
	color: white;
	padding: 12px 16px;
	position: sticky;
	top: 0;
	z-index: 9999;
	border-bottom: 1px solid #3c82f6;
	@include breakpoint(lg) {
		padding: 16px 0;
	}
}

.cookie-consent-body {
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	text-align: center;

	@include breakpoint(lg) {
		gap: 16px;
		flex-direction: row;
		text-align: left;
	}
}
</style>
